<template>
  <v-dialog
    v-model="showDialog"
    max-width="450"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text v-html="text"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="showDialog=false"
        >
          {{ deny }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="doConfirm()"
        >
          {{ confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data () {
    return {
      showDialog: false,
      title: '',
      text: '',
      confirm: '',
      deny: '',
      resolve: null
    }
  },
  methods: {
    show ({ title, text, confirm = 'Zustimmen', deny = 'Abbrechen' }) {
      this.title = title
      this.text = text
      this.confirm = confirm
      this.deny = deny
      this.showDialog = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    doConfirm () {
      this.showDialog = false
      this.resolve()
    }
  }
}
</script>

<style scoped>

</style>
